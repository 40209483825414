<template>
  <div class="rounded d-flex flex-column justify-end text-right">
    <div
      v-for="(category, i) in categories"
      :key="`category-${i}`"
      class="d-flex justify-end"
    >
      <v-hover
        v-slot="{ hover }"
      >
        <v-btn
          small
          class="py-6"
          :class="getCategoryClass(category, i, hover)"
          :color="`#${category.color}`"
          :dark="getTextBrightness(category) === 'dark'"
          @click="() => $emit('toggle-category', category.id)"
        >
          <v-expand-x-transition>
            <div
              v-show="hover"
            >
              <v-icon
                class="mr-2"
                small
                v-text="isCategoryHidden(category) ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
              />
              <span
                class="mr-2"
              >
                {{ category.name }}
              </span>
            </div>
          </v-expand-x-transition>
          <v-icon
            right
            size="22"
            class="mx-1"
          >
            {{ 'mdi-' + category.icon }}
          </v-icon>
        </v-btn>
      </v-hover>
    </div>
  </div>
</template>

<script>
  import { getTextBrightness } from '@utils/color';

  export default {
    props: {
      categories: {
        type: Array,
        required: true,
      },
      hiddenCategories: {
        type: Array,
        required: true,
      },
    },

    methods: {
      getTextBrightness (category) {
        return getTextBrightness('#' + category.color);
      },

      isCategoryHidden (category) {
        return this.hiddenCategories.indexOf(category.id) !== -1;
      },

      getCategoryClass (category, index, hover) {
        const classes = ['rounded-0'];

        if (hover) {
          classes.push('rounded-l');
        }

        if (index === 0) {
          classes.push('rounded-t');
        }
        if (index === this.categories.length - 1) {
          classes.push('rounded-b');
        }

        if (this.isCategoryHidden(category)) {
          classes.push('category-hidden');
        }

        return classes.join(' ');
      },
    },
  };
</script>

<style lang="sass" scoped>
  .category-hidden
    opacity: 0.3
</style>
